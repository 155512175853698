import "./about.css"
import Holiday from "../../img/43729856_7583_2 - Copy (2).jpg"
import Lehigh from "../../img/LUImage.png"
import PWC from "../../img/800px-PricewaterhouseCoopers_Logo.svg.png"
import Evonik from "../../img/Evonik_logo_2020.png"

const About = () => {
    return (
        <div className="a">
            <div className="a-left">
                <div className="a-card bg"></div>
                <div className="a-card">
                    <img src={Holiday} alt="" className="a-img" />
                </div>
            </div>
            <div className="a-right">
                <h1 className="a-title">Professional Timeline</h1>
                {/*}<p className="a-sub">
                    Subtitle
                </p>
                <p className="a-desc">
                    Description
                </p>{*/}
                <div className="a-school">
                    <img src={PWC} alt="" className="a-work-img" />
                    <div className="a-school-texts">
                        <h4 className="a-school-title">PricewaterhouseCoopers (2022 - )</h4>
                        <p className="a-school-desc">
                            I am currently working as an Associate at PwC.
                        </p>
                    </div>
                </div>
                <div className="a-school">
                    <img src={Lehigh} alt="" className="a-school-img" />
                    <div className="a-school-texts">
                        <h4 className="a-school-title">Lehigh University (2018 - 2022)</h4>
                        <p className="a-school-desc">
                            At Lehigh I studied Chemical Engineering in the 
                            Integrated Business and Engineering (IBE) Honors Program and
                            minored in Computer Science.
                        </p>
                        <p className="a-school-desc">
                        I graduated Summa Cum Laude in May of 2022.
                        </p>

                    </div>
                </div>
                
                <div className="a-school">
                    <img src={Evonik} alt="" className="a-evonik-img" />
                    <div className="a-school-texts">
                        <h4 className="a-school-title">Evonik (2020, 2021)</h4>
                        <p className="a-school-desc">
                            I worked for two summers for Evonik, a German specialty chemicals company.
                        </p>
                        <p className="a-school-desc">
                        I worked in the Allentown, PA pilot plant and at Evonik's pharmaceutical plant in West Lafayette, IN.

                        </p>
                        <p className="a-school-desc">
                        &nbsp;
                        </p>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default About
