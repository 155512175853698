import "./intro.css"
import Me from "../../img/IMG-5646.jpg"
import LI from "../../img/LinkedInSmall.png"
import Twitter from "../../img/logo-twitter-circle-png-transparent-image-1 (1).png"

const Intro = () => {
    return (
        <div className="i reveal2"> {/*}reveal2{*/}
            <div className="i-left">
                <div className="i-profile-wrapper">
                    <div className="i-border">
                        <div className="i-bg">
                            <img src={Me} alt="" className="i-img" />
                        </div>
                    </div>
                </div>
                
            </div>
            
            <div className="i-right">
                <div className="i-right-wrapper">
                    {/*}<h2 className="i-intro">Hello, My name is</h2>{*/}
                    <h1 className="i-name">John Klikushin</h1>
                    <div className="i-title">
                        <a href="https://www.linkedin.com/in/johnklikushin/" >
                            <img alt="LinkedIn" src={LI}></img>
                        </a>
                        &nbsp;&nbsp;
                        <a href="https://www.twitter.com/johnklikushin/" >
                            <img alt="Twitter" src={Twitter}></img>
                        </a>
                    </div>
                    
                    <div className="i-intro" onclick="window.location='http://google.com">
                        Bethlehem │ NYC
                    </div>
                    
                    {/*}
                    <div className="i-title">
                        <div className="i-title-wrapper">
                            <div className="i-title-item">Consultant</div>
                            <div className="i-title-item">Programmer</div>
                            <div className="i-title-item">Real Person</div>
                            <div className="i-title-item">Motivated Worker</div>
                            <div className="i-title-item">Influencer (Debatable)</div>
                        </div>   
                    </div>
    {*/}
                </div>
                {/*}
                    <div className="i-birdsframe">
                        <img src={Birds} alt="" className="i-birds"/>
                    </div>
                {*/}

            </div>
            
        </div>
        
    )
}
function reveal2() {
    var reveals = document.querySelectorAll(".reveal2");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 800;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
}
window.addEventListener("scroll", reveal2);



export default Intro