import "./downarrow.css"
import Arrow from "../../img/v-removebg-preview.png"

const Downarrow = () => {
    return (
        <div className="d">
            <div className="container reveal">
                <img src={Arrow} alt="" className="d-img" />
            </div>
        </div>
    )
}

function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
}
window.addEventListener("scroll", reveal);

export default Downarrow

