import "./footer.css"

const Footer = () => {
    return (
        <div className="f">
            <div className="footer-wrap">
                <div className="f-text">© 2022 John Klikushin 
                │ This website was built with ReactJS. &nbsp;
                
                </div>
                
            </div>
        </div>
    )
}

export default Footer