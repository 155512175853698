import Intro from "./components/intro/intro.jsx";
import About from "./components/about/about.jsx";
import Footer from "./components/footer/footer.jsx";
import Downarrow from "./components/downarrow/downarrow.jsx";
import Skills from "./components/skills/skills.jsx";

const App = () => {
  return (
    <div>
      <Intro/>
      <Downarrow/>
      <About/>
      
      <Footer/>
      
    </div>
  );
};

export default App;